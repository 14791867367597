<template>
  <div class="my-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.firstName')} *`" />
          <v-text-field
            class="mt-1"
            v-model="userData.person.firstName"
            :rules="genericPeopleNameRule"
            :placeholder="`${$t('profile.firstName')}`"
            outlined
            dense
            :disabled="isFieldDisabled || hasCard"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.lastName')} *`" />
          <v-text-field
            class="mt-1"
            v-model="userData.person.lastName"
            :rules="genericPeopleNameRule"
            :placeholder="`${$t('profile.lastName')}`"
            outlined
            dense
            :disabled="isFieldDisabled || hasCard"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.birthDate')} *`" />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                :rules="[requiredRules()]"
                placeholder="gg/mm/aaaa"
                class="mt-1"
                required
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="isFieldDisabled || hasCard"
                append-icon="$calendar"
                @click:append="menu = true"
                readonly
              >
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              color="primary"
              v-model="userData.person.birthDate"
              :rules="[requiredRules()]"
              min="1910-01-01"
              :max="maxBirthDate"
              first-day-of-week="1"
              type="date"
              :picker-date.sync="pickerDate"
              no-title
              @input="onBirthdateInput"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.gender')}`" />
          <v-radio-group v-model="userData.person.gender" row class="mt-1">
            <v-radio label="F" value="F" color="default"></v-radio>
            <v-radio label="M" value="M" color="default"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.workPhone')} *`" />
          <v-text-field
            class="mt-1"
            v-model="userData.contact.homePhone"
            :rules="telephoneNumber"
            :placeholder="`${$t('profile.contacts.workPhone')}`"
            outlined
            dense
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.mobilePhone')} *`" />
          <v-text-field
            class="mt-1"
            v-model="userData.phone"
            :rules="phoneRules"
            :placeholder="`${$t('profile.contacts.mobilePhone')}`"
            outlined
            dense
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- email -->
          <label v-html="`${$t('profile.contacts.email')}*`" />
          <v-text-field
            v-model="userData.email"
            :placeholder="`${$t('profile.contacts.email')}*`"
            :rules="emailRules"
            required
            outlined
            dense
            class="mt-1"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- confirm email -->
          <label v-html="`${$t('profile.email.emailConfirm')}*`" />
          <v-text-field
            v-model="userData.emailConfirm"
            :placeholder="`${$t('profile.email.emailConfirm')}`"
            :rules="emailConfirmRules"
            outlined
            dense
            @paste.prevent
            class="mt-1"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- password -->
          <label v-html="`${$t('profile.password')}*`" />
          <v-text-field
            v-model="userData.password"
            :placeholder="`${$t('profile.password')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            class="mt-1"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- conferma password -->
          <label v-html="`${$t('profile.passwordConfirm')}*`" />
          <v-text-field
            :placeholder="`${$t('profile.passwordConfirm')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            @paste.prevent
            class="mt-1"
          />
        </v-col>
        <v-col cols="12">
          <span class="text-body-1 font-weight-600">{{
            $t("register.user.emailNote")
          }}</span>
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.store')} *`" />
          <v-select
            :placeholder="`${$t('profile.store')}`"
            v-model="userData.companyId"
            :rules="[requiredRules()]"
            :items="stores"
            item-text="name"
            item-value="storeId"
            outlined
            dense
            class="mt-1"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style lang="scss">
.document-list {
  border: 1px solid $primary !important;
  border-radius: 4px !important;
}
</style>
<script>
import {
  requiredValue,
  isMobilePhone,
  isTelephone,
  isEmail,
  minLength,
  minSymbolCharacter,
  minCapitalCharacter,
  genericPeopleName
} from "~/validator/validationRules";
import StoreService from "~/service/storeService";

export default {
  name: "NewCustomer",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    response: { type: Object },
    hasCard: { type: Boolean, default: false }
  },
  data() {
    return {
      isValid: false,
      menu: false,
      showPassword: false,
      stores: [],
      requiredRules: requiredValue,
      emailRules: [requiredValue(), isEmail()],
      phoneRules: [requiredValue(), isMobilePhone()],
      telephoneNumber: [requiredValue(), isTelephone()],
      genericPeopleNameRule: [requiredValue(), genericPeopleName()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "L'e-mail non coincidono"
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      pickerDate: this.$dayjs()
        .subtract(25, "years")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("validate", _this.valid);
        }
      }, 200);
    },
    onBirthdateInput() {
      this.menu = false;
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async fetchStoreList() {
      StoreService.getStores().then(data => {
        if (data.stores) {
          this.stores = data.stores;
        } else {
          this.stores = [];
        }
      });
    }
  },
  computed: {
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    }
  },
  async created() {
    await this.fetchStoreList();
  }
};
</script>
