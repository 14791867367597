<template>
  <v-container class="registration-user-container px-3">
    <CategoryTitle
      :category="category"
      class="mt-0 my-lg-6"
      :add-container="false"
    />
    <div>
      <label>{{ $t("register.user.alreadyCustomer") }}?*</label>
      <v-radio-group v-model="hasCard" row @change="resetUserData">
        <v-radio
          color="default"
          :label="$t('common.yes')"
          :value="true"
        ></v-radio>
        <v-radio
          color="default"
          :label="$t('common.no')"
          :value="false"
          @click.stop="dialog = true"
        ></v-radio>
      </v-radio-group>
      <v-dialog v-model="dialog" max-width="460">
        <v-card @click="clicked">
          <div class="d-flex align-start justify-center">
            <v-card-text class="pt-0 pr-0">
              <p
                class="content"
                v-html="$t('register.registrationNoCardConfirmPopup.content')"
              ></p>
            </v-card-text>
            <v-btn
              depressed
              plain
              class="d-flex align-start"
              icon
              @click.stop="dialog = false"
            >
              <v-icon>$close</v-icon>
            </v-btn>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" outlined depressed @click="dialog = false">
              {{ $t("common.no") }}
            </v-btn>

            <v-btn color="primary" depressed to="/page/diventa-socio-coop">
              {{ $t("common.yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="hasCard">
      <div>
        <UserEmailAddress
          ref="userEmailAddressDataForm"
          :userData.sync="userData"
          :hasCard="hasCard"
          :isFieldDisabled="step == 2"
        />
      </div>
    </div>
    <div v-if="!hasCard || step == 2">
      <h3 v-html="$t('register.user.title')"></h3>
      <span class="description" v-html="$t('register.user.description')" />
      <NewCustomer
        ref="newCustomerDataForm"
        :userData.sync="userData"
        :hasCard="hasCard"
        :isFieldDisabled="hasCard == null"
      />
      <v-row>
        <v-col cols="12"
          ><div v-if="userData.companyId">
            <PrivacyOptions
              ref="privacyOptionsForm"
              :storeId="userData.companyId"
              :userData="userData"
              :showUpdateButton="false"
            /></div
        ></v-col>
      </v-row>
    </div>
    <div>* {{ $t("profile.requiredFields") }}</div>
    <div class="d-flex justify-end">
      <v-btn
        v-if="hasCard && step != 2"
        @click="checkCard"
        depressed
        large
        color="primary"
        >{{ $t("register.user.verify") }}</v-btn
      >
      <v-btn v-else @click="register" depressed large color="primary">{{
        $t("register.user.signIn")
      }}</v-btn>
    </div>
    <ResponseMessage :response="response" class="mt-4" />
  </v-container>
</template>

<style lang="scss">
.content {
  font-size: 1rem;
}
.registration-user-container {
  //color: var(--v-primary-base);
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 14px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .img-wraper {
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import NewCustomer from "@/components/profile/form/NewCustomer.vue";
import PrivacyOptions from "@/components/profile/form/PrivacyOptions.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
//import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import CustomService from "@/service/customService";
import analyticsService from "@/commons/service/analyticsService";

export default {
  name: "RegistrationUser",
  data() {
    return {
      dialog: false,
      response: {},
      isSocialLogin: false,
      hasCard: true,
      step: 1,
      userInformationFields: [],
      userPrivacyFields: [],
      userInfoAndPrivacyFields: [],
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        person: {
          personInfos: []
        },
        contact: {}
      }
    };
  },
  mixins: [categoryMixins, clickHandler],
  components: {
    CategoryTitle,
    UserEmailAddress,
    NewCustomer,
    ResponseMessage,
    PrivacyOptions
  },
  methods: {
    // async openConfirmPopup() {
    //   let res = await this.$dialog.show(CustomConfirmDialog, {
    //     waitForResult: true,
    //     path: "register.registrationNoCardConfirmPopup",
    //     dialogClass: "registration-no-card-dialog"
    //   });
    //   if (!res) {
    //     this.hasCard = true;
    //   }
    // },
    resetUserData() {
      this.userData.fidelityCard = null;
      this.userData.person = { personInfos: [] };
    },
    async register() {
      this.response = {};
      let userDataValid = this.$refs.newCustomerDataForm.$refs.form.validate();
      let privacyDataValid = this.userData.companyId
        ? this.$refs.privacyOptionsForm.$refs.form.validate()
        : false;
      if (userDataValid && privacyDataValid) {
        await this.$refs.privacyOptionsForm.applyChages();
        let _this = this;
        CustomService.registerUser(this.userData, this.hasCard ? 1 : 0).then(
          data => {
            if (data.response.status == 0) {
              analyticsService.signUp(
                _this.hasCard ? "card" : "nocard",
                data.data.user.userId
              );
              if (window.fbq) window.fbq("track", "CompleteRegistration");

              this.$router.push({ name: "RegistrationCompleted" });
            } else {
              this.response = data.response;
            }
          }
        );
      } else {
        /**
         * If only user infotmation fields are present
         */
        if (this.$refs.newCustomerDataForm) {
          this.userInformationFields = this.$refs.newCustomerDataForm.$refs.form.$children.filter(
            field => {
              if (field.type === "button") {
                return false;
              }
              if (field.$el && field.$el.className.includes("menu")) {
                return false;
              }
              // eslint-disable-next-line no-prototype-builtins
              if (field.hasOwnProperty("valid")) {
                return !field.valid;
              }
              return true;
            }
          );
          /**
           * Focus on the first invalid field in user information fields list
           */
          this.scrollAndFocusOnFirstInvalidField(this.userInformationFields);
        }
        /**
         * If both user infotmation and privacy fields are present
         */
        if (this.$refs.newCustomerDataForm && this.$refs.privacyOptionsForm) {
          this.userPrivacyFields = this.$refs.privacyOptionsForm.$refs.form.$children.filter(
            field => !field.valid && field.type !== "button"
          );
        }
        /**
         * Focus on the first invalid field in user information and privacy fields list
         */
        if (this.userInformationFields && this.userPrivacyFields) {
          this.userInfoAndPrivacyFields = [
            ...this.userInformationFields,
            ...this.userPrivacyFields
          ];
          this.scrollAndFocusOnFirstInvalidField(this.userInfoAndPrivacyFields);
        }
      }
    },
    scrollAndFocusOnFirstInvalidField(fields) {
      if (fields.length > 0) {
        fields[0].$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        fields[0].$el.focus();
      }
    },
    checkCard() {
      if (this.$refs.userEmailAddressDataForm.$refs.form.validate()) {
        CustomService.checkCard(
          this.userData.fidelityCard,
          this.userData.person.birthDate
        ).then(data => {
          if (data.response.status == 0) {
            this.step = 2;
            this.userData = data.data.user;
          } else {
            this.response = data.response;
          }
        });
      }
    }
  }
};
</script>
