<template>
  <div class="user-email-address-container mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <v-col cols="12"
          ><div class="d-flex img-wraper">
            <img
              alt="CardExample"
              width="450"
              src="/img_layout/cardExample.png"
            /></div
        ></v-col>
        <!--Card-->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.cardNumber')} *`" />
          <v-text-field
            class="mt-1"
            v-model="userData.fidelityCard"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.cardNumberPlaceholder')}`"
            required
            outlined
            dense
            hide-details="auto"
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <!--Birth Date-->
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <label v-html="`${$t('profile.birthDate')} *`" />
              <v-text-field
                v-model="birthDate"
                placeholder="gg/mm/aaaa"
                :rules="dateRules"
                class="mt-1"
                required
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="isFieldDisabled"
                hide-details="auto"
                append-icon="$calendar"
                @click:append="menu = true"
                readonly
              >
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              color="primary"
              v-model="userData.person.birthDate"
              min="1910-01-01"
              :max="maxBirthDate"
              first-day-of-week="1"
              type="date"
              :picker-date.sync="pickerDate"
              no-title
              @input="onBirthdateInput"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "UserEmailAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    hasCard: { type: Boolean, default: false }
  },
  components: {},
  data() {
    return {
      isValid: false,
      showPassword: false,
      passwordConfirm: null,
      menu: false,
      response: {},
      dateRules: [requiredValue("Digitare la data di nascita")],
      requiredRules: requiredValue,
      pickerDate: this.$dayjs()
        .subtract(25, "years")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    },
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", true, true);
    },
    onBirthdateInput() {
      this.menu = false;
    }
  },

  mounted() {}
};
</script>
